import './Animation.css';
import { ReactComponent as BlockchainAnimation} from './Animation.svg';

const Animation = () => {

return(
<div className="container">
    <BlockchainAnimation/>
</div>
);

}

export default Animation;